<template>
  <header class="header">
    <button id="btn-top-left" @click="configMode" v-show="logged">Configurer</button>
    <button id="btn-top-leftt" @click="logout" v-show="logged">Déconnexion</button>
    <h1>La Vie</h1>
  </header>
  <div class="container" style="width: 100%; max-width: none; margin:0; padding:0" v-show="!config && logged">
    <div class="row" style="height: 90vh;">

      <!-- First Column: Basic Form -->
      <div class="four columns column-adjustement" style="border-right: 1px solid #ddd;">
        <h5 class="header">Commun</h5>
        <div class="row">
          <div class="six columns">
            <label for="input1">William</label>
            <input class="u-full-width" type="text" id="input1" placeholder="Salaire Will" v-model.number="salaireWilliam.value" @input="validateNumber" @change="calculMontant">
          </div>
          <div class="six columns">
            <label for="input2">Chloé</label>
            <input class="u-full-width" type="text" id="input2" placeholder="Salaire Chloé" v-model.number="salaireChloe.value" @input="validateNumber" @change="calculMontant">
          </div>
        </div>

        <!-- Deux labels informatifs -->
        <div class="row" style="padding-top:20px;">
          <div class="six columns">
            <label class="color-william">{{ montantWill }}€</label>
          </div>
          <div class="six columns">
            <label class="color-chloe">{{ montantChloe }}€</label>
          </div>
        </div>

        <!-- Tableau avec dix éléments d'exemple -->
        <label style="padding-top:20px;">Dépenses fixes</label>
        <table class="u-full-width">
          <thead>
            <tr>
              <th>Catégorie</th>
              <th>€</th>
              <th>Reste</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Loyer</td>
              <td>{{ Loyer.value }}€</td>
              <td>{{ resteLoyer }}€</td>
            </tr>
            <tr>
              <td>Electricité</td>
              <td>{{ Electricite.value }}€</td>
              <td>{{ resteElectricite }}€</td>
            </tr>
            <tr>
              <td>Internet</td>
              <td>{{ Internet.value }}€</td>
              <td>{{ resteInternet }}€</td>
            </tr>
            <tr>
              <td>Chats</td>
              <td>{{ Chat.value }}€</td>
              <td>{{ resteChat }}€</td>
            </tr>
          </tbody>
        </table>

        <label style="padding-top:20px;">Dépenses variables</label>
        <table class="u-full-width">
          <thead>
            <tr>
              <th>Catégorie</th>
              <th>%</th>
              <th>€</th>
              <th>Reste</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Alimentation</td>
              <td>{{ratioAlimentation.value * 100}}</td>
              <td>{{ Alimentation }}€</td>
              <td>{{ resteAlimentation }}€</td>
            </tr>
            <tr>
              <td>Sorties</td>
              <td>{{ ratioSortie.value * 100 }}</td>
              <td>{{ Sortie }}€</td>
              <td>{{ resteSortie }}€</td> 
            </tr>
          </tbody>
        </table>
        <p>Calcul après déduction des dépenses obligatoires fixes</p>
      </div>
      

      <!-- Second Column: Basic Table -->
      <div class="four columns column-adjustement" style="border-right: 1px solid #ddd;">
        <h5 class="header"> Historique d'achats</h5>

        <div class="row">
          <div class="four columns">
            <select class="u-full-width" placeholder="Catégorie" v-model="newCat">
              <option v-for="categorie in categories" :key="categorie" :value="categorie">
                {{ categorie }}
              </option>
            </select>
          </div>
          <div class="four columns">
            <input class="u-full-width" type="text" placeholder="€" v-model.number="newMount" @input="validateNumber">
          </div>
          <div class="four columns">
            <input class="u-full-width date-picker" type="date" v-model="newDate">
          </div>
        </div>
        <div class="row">
          <div class="five columns">
            <input class="u-full-width" type="text" placeholder="Description" v-model="newLabel" >
          </div>
          <div class="three columns">
            <select class="u-full-width" placeholder="Catégorie" v-model="newPerson">
              <option v-for="person in persons" :key="person" :value="person">
                {{ person }}
              </option>
            </select>
          </div>
          <div class="four columns">
            <button @click="ajouterAchat">Ajouter</button>
          </div>
        </div>

        <table class="u-full-width">
          <thead>
            <tr>
              <th style="padding-left:10px;">Catégorie</th>
              <th>€</th>
              <th>Dépense</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(achat, index) in achatsTries" :key="index" v-bind:class="dynamicCSS(achat.person)">
              <td style="padding-left:10px;">{{ achat.categorie }}</td>
              <td>{{ achat.montant }}€</td>
              <td>{{ achat.label }}</td>
              <td>{{ achat.date }}</td>
              <td><img src="../assets/retirer.png" style="width: 15px; cursor:pointer" @click="deleteAchat(achat.id)"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Third Column: Split into 2 Rows -->
      <div class="four columns column-adjustement">
        <h5 class="header"> Perso</h5>
        <div style="border-bottom: 1px solid #ddd; border-top: 1px solid #ddd; padding-top:20px;">
          <label class="color-chloe">Dépense Chloé {{ resteAvantChloe }}€</label>
          <table class="u-full-width">
            <thead>
              <tr>
                <th>Catégorie</th>
                <th>€</th>
                <th>Reste</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Abonnements</td>
                <td>{{ abonnementChloe.value }}€</td>
                <td>{{ resteAbonnementChloe }}€</td>
              </tr> 
              <tr>
                <td>Divers</td>
                <td>{{ diversChloe.value }}€</td>
                <td>{{ resteDiversChloe }}€</td>
              </tr>
            </tbody>
          </table>
          <table class="u-full-width">
            <thead>
              <tr>
                <th>Epargne</th>
                <th>%</th>
                <th>€</th>
                <th>Reste</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sécurité</td>
                <td>{{ ratioSecurite.value * 100 }}</td>
                <td>{{ calculEpargne("ratioSecurite", "Chloe") }}€</td>
                <td>{{ resteSecuriteChloe }}€</td>
              </tr>
              <tr>
                <td>Vacances</td>
                <td>{{ ratioVacances.value * 100 }}</td>
                <td>{{ calculEpargne("ratioVacances", "Chloe") }}€</td>
                <td>{{ resteVacancesChloe }}€</td>
              </tr>
              <tr>
                <td>Investissements</td>
                <td>{{ ratioInvest.value * 100 }}</td>
                <td>{{ calculEpargne("ratioInvest", "Chloe") }}€</td>
                <td>{{ resteInvestissementChloe }}€</td>
              </tr>
              <tr>
                <td>Projet</td>
                <td>{{ ratioProjet.value * 100 }}</td>
                <td>{{ calculEpargne("ratioProjet", "Chloe") }}€</td>
                <td>{{ resteProjetChloe }}€</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="padding-top:20px;">
          <label class="color-william">Dépense William {{ resteAvantWill }}€</label>
          <table class="u-full-width">
            <thead>
              <tr>
                <th>Catégorie</th>
                <th>€</th>
                <th>Reste</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Abonnements</td>
                <td>{{ abonnementWilliam.value }}€</td>
                <td>{{ resteAbonnementWilliam }}€</td>
              </tr>
              <tr>
                <td>Divers</td>
                <td>{{ diversWilliam.value }}€</td>
                <td>{{ resteDiversWilliam }}€</td>
              </tr>
            </tbody>
          </table>
          <table class="u-full-width">
            <thead>
              <tr>
                <th>Epargne</th>
                <th>%</th>
                <th>€</th>
                <th>Reste</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sécurité</td>
                <td>{{ ratioSecurite.value * 100 }}</td>
                <td>{{ calculEpargne("ratioSecurite", "Will") }}€</td>
                <td>{{ resteSecuriteWilliam }}€</td>
              </tr>
              <tr>
                <td>Vacances</td>
                <td>{{ ratioVacances.value * 100 }}</td>
                <td>{{ calculEpargne("ratioVacances", "Will") }}€</td>
                <td>{{ resteVacancesWilliam }}€</td>
              </tr>
              <tr>
                <td>Investissements</td>
                <td>{{ ratioInvest.value * 100 }}</td>
                <td>{{ calculEpargne("ratioInvest", "Will") }}€</td>
                <td>{{ resteInvestissementWilliam }}€</td>
              </tr>
              <tr>
                <td>Projet</td>
                <td>{{ ratioProjet.value * 100 }}</td>
                <td>{{ calculEpargne("ratioProjet", "Will") }}€</td>
                <td>{{ resteProjetWilliam }}€</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  
  <div class="container" style="width: 100%; max-width: none; margin:0; padding:0" v-show="config && logged">
    <div class="row">
      <div class="four columns" style="border-right: 1px solid #ddd; padding-left:20px; padding-right:20px;">
        <h5 class="header">Commun</h5>
        <div class="row">
          <div class="six columns">
            <label>Loyer</label>
            <input class="u-full-width" type="text" placeholder="Loyer" v-model.number="Loyer.value" @input="validateNumber" @change="updateConfig(Loyer.id, Loyer.name)">
            <label>Internet</label>
            <input class="u-full-width" type="text" placeholder="Internet" v-model.number="Internet.value" @input="validateNumber" @change="updateConfig(Internet.id, Internet.name)">
            <label>Ratio alimentation (min:0 max1;)</label>
            <input class="u-full-width" type="text" placeholder="Ratio Alimentation" v-model="ratioAlimentation.value" @input="validateNumber" @change="updateConfig(ratioAlimentation.id, ratioAlimentation.name)">
          </div>
          <div class="six columns">
            <label>Electricité</label>
            <input class="u-full-width" type="text" placeholder="Electricité" v-model.number="Electricite.value" @input="validateNumber" @change="updateConfig(Electricite.id, Electricite.name)">
            <label>Chat</label>
            <input class="u-full-width" type="text" placeholder="Chat" v-model.number="Chat.value" @input="validateNumber" @change="updateConfig(Chat.id, Chat.name)">
            <label>Ratio sortie (min:0 max1;)</label>
            <input class="u-full-width" type="text" placeholder="Ratio Sortie" v-model="ratioSortie.value" @input="validateNumber" @change="updateConfig(ratioSortie.id, ratioSortie.name)">
          </div>
        </div>
      </div>
      <div class="four columns" style="border-right: 1px solid #ddd; padding-right:20px;">
        <h5 class="header">Epargne</h5>
        <div class="row">
          <div class="six columns">
            <label>Ratio Sécurité (min:0 max1;)</label>
            <input class="u-full-width" type="text" placeholder="Ratio Securite" v-model="ratioSecurite.value" @input="validateNumber" @change="updateConfig(ratioSecurite.id, ratioSecurite.name)">
            <label>Ratio Vacances (min:0 max1;)</label>
            <input class="u-full-width" type="text" placeholder="Ratio Vacances" v-model="ratioVacances.value" @input="validateNumber" @change="updateConfig(ratioVacances.id, ratioVacances.name)">
          </div>
          <div class="six columns">
            <label>Ratio Invest (min:0 max1;)</label>
            <input class="u-full-width" type="text" placeholder="Ration Investissement" v-model="ratioInvest.value" @input="validateNumber" @change="updateConfig(ratioInvest.id, ratioInvest.name)">
            <label>Ratio Projet (min:0 max1;)</label>
            <input class="u-full-width" type="text" placeholder="Ratio Projet" v-model="ratioProjet.value" @input="validateNumber" @change="updateConfig(ratioProjet.id, ratioProjet.name)">
          </div>
        </div>
      </div>
      <div class="four columns" style="padding-right:20px;">
      <h5 class="header">Perso</h5>
       <div class="row">
        <div class="six columns">
          <label>Abonnement Chloé</label>
          <input class="u-full-width" type="text" placeholder="Abonnement Chloe" v-model.number="abonnementChloe.value" @input="validateNumber" @change="updateConfig(abonnementChloe.id, abonnementChloe.name)">
          <label>Divers Chloé</label>
          <input class="u-full-width" type="text" placeholder="Divers Chloe" v-model.number="diversChloe.value" @input="validateNumber" @change="updateConfig(diversChloe.id, diversChloe.name)">
        </div>
        <div class="six columns">
          <label>Abonnement William</label>
          <input class="u-full-width" type="text" placeholder="Abonnement William" v-model.number="abonnementWilliam.value" @input="validateNumber" @change="updateConfig(abonnementWilliam.id, abonnementWilliam.name)">
          <label>Divers William</label>
          <input class="u-full-width" type="text" placeholder="Divers William" v-model.number="diversWilliam.value" @input="validateNumber" @change="updateConfig(diversWilliam.id, diversWilliam.name)">
        </div>
       </div>
      </div>
    </div>
  </div>

  <div class="log-container">
    <input class="u-full-width two columns" type="password" v-model="tryTo" @change="tryToIn" v-show="!logged">
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HelloWorld',
  data() {
    //#region Date

    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];

    //#endregion
    
    return {
      salaireWilliam: { id: '', name: '', value: 0 },
      salaireChloe: { id: '', name: '', value: 0 },

      montantWill: '',
      montantChloe: '',

      abonnementChloe: { id: '', name: '', value: 0 },
      diversChloe: { id: '', name: '', value: 0 },

      abonnementWilliam: { id: '', name: '', value: 0 },
      diversWilliam: { id: '', name: '', value: 0 },

      ratioSecurite: { id: '', name: '', value: 0 },
      ratioVacances: { id: '', name: '', value: 0 },
      ratioInvest:  { id: '', name: '', value: 0 },
      ratioProjet: { id: '', name: '', value: 0 },

      Loyer: { id: '', name: '', value: 0 },
      Electricite: { id: '', name: '', value: 0 },
      Internet: { id: '', name: '', value: 0 },
      Chat: { id: '', name: '', value: 0 },
      
      ratioAlimentation: { id: '', name: '', value: 0 },
      ratioSortie: { id: '', name: '', value: 0 },

      achats: [
      ],

      categories: [
        "Abonnement",
        "Alimentation",
        "Chat",
        "Divers",
        "Electricite",
        "Internet",
        "Investissement",
        "Loyer",
        "Projet",
        "Securite",
        "Sortie",
        "Vacances",
      ],

      persons: [
        "Commun",
        "Chloé",
        "William"
      ],

      newCat: "Abonnement",
      newMount: "",
      newDate: currentDate,
      newLabel: "",
      newPerson: "Commun",

      tryTo: '',
      pass: {id: '', name: '', value: '' },
      config: false,
      logged: false
    };
  },
  computed: {

    //#region total

    totalDepenses() {
      return Math.ceil(this.Loyer.value + this.Electricite.value + this.Internet.value + this.Chat.value);
    },

    revenuTotal() {
      return Math.floor(this.salaireWilliam.value + this.salaireChloe.value);
    },

    resteAvantChloe() {
      return Math.floor(this.salaireChloe.value - this.montantChloe);
    },

    resteAvantWill() {
      return Math.floor(this.salaireWilliam.value - this.montantWill);
    },

    resteApresChloe() {
      return Math.floor(this.resteAvantChloe - this.abonnementChloe.value - this.diversChloe.value);
    },

    resteApresWill() {
      return Math.floor(this.resteAvantWill - this.abonnementWilliam.value - this.diversWilliam.value);
    },

    //#endregion

    //#region data commun

    Alimentation() {
      return Math.floor((this.revenuTotal - this.totalDepenses) * this.ratioAlimentation.value);
    },

    Sortie() {
      return Math.floor((this.revenuTotal - this.totalDepenses) * this.ratioSortie.value);
    },

    resteAlimentation() {
      return this.calculerReste('Alimentation');
    },
    resteSortie() {
      return this.calculerReste('Sortie');
    },
    resteLoyer() {
      return this.calculerReste('Loyer');
    },
    resteElectricite() {
      return this.calculerReste('Electricite');
    },
    resteInternet() {
      return this.calculerReste('Internet');
    },
    resteChat() {
      return this.calculerReste('Chat');
    },

    //#endregion

    //#region data perso

    resteAbonnementChloe() {
      return this.calculerRestePerso("Abonnement", this.abonnementChloe.value, "Chloé");
    },

    resteDiversChloe() {
      return this.calculerRestePerso("Divers", this.diversChloe.value, "Chloé");
    },

    resteSecuriteChloe() {
      return this.calculerRestePerso('Securite', this.calculEpargne("ratioSecurite", "Chloe"), "Chloé");
    },

    resteVacancesChloe() {
      return this.calculerRestePerso('Vacances', this.calculEpargne("ratioVacances", "Chloe"), "Chloé");
    },

    resteInvestissementChloe() {
      return this.calculerRestePerso('Investissement', this.calculEpargne("ratioInvest", "Chloe"), "Chloé");
    },

    resteProjetChloe() {
      return this.calculerRestePerso('Projet', this.calculEpargne("ratioProjet", "Chloe"), "Chloé");
    },

    resteAbonnementWilliam() {
      return this.calculerRestePerso('Abonnement', this.abonnementWilliam.value, "William");
    },

    resteDiversWilliam() {
      return this.calculerRestePerso('Divers', this.diversWilliam.value, "William");
    },

    resteSecuriteWilliam() {
      return this.calculerRestePerso('Securite', this.calculEpargne("ratioSecurite", "Will"), "William");
    },

    resteVacancesWilliam() {
      return this.calculerRestePerso('Vacances', this.calculEpargne("ratioVacances", "Will"), "William");
    },

    resteInvestissementWilliam() {
      return this.calculerRestePerso('Investissement', this.calculEpargne("ratioInvest", "Will"), "William");
    },

    resteProjetWilliam() {
      return this.calculerRestePerso('Projet', this.calculEpargne("ratioProjet", "Will"), "William");
    },
    

    //#endregion

    //#region tools

    achatsTries() {
      return this.achats.slice().sort((a, b) => {
        const dateA = a.date;
        const dateB = b.date;
        return dateB.substr(0,2) - dateA.substr(0,2);
      });
    },

    //#endregion
    
  },
  mounted() {
    this.fetchAchats();
    this.fetchConfig();
    this.calculMontant();
  },
  
  methods: {
    
    //#region calcul

    calculMontant(){
      this.updateConfig(this.salaireWilliam.id, this.salaireWilliam.name);
      this.updateConfig(this.salaireChloe.id, this.salaireChloe.name);

      let ratio = this.salaireWilliam.value / this.revenuTotal;
      this.montantWill = Math.ceil((this.Alimentation + this.Sortie + this.totalDepenses) * ratio);
      this.montantChloe = (this.Alimentation + this.Sortie + this.totalDepenses) - this.montantWill;
    },

    calculerReste(categorie) {
      let montant = 0;
      if(typeof this[categorie] === "object")
        montant = this[categorie].value;
      else
        montant = this[categorie];

      return Math.floor(montant - this.achats.reduce((total, achat) => {
        if (achat.categorie === categorie) {
          return total + achat.montant;
        }
        return total;
      }, 0));
    },

    calculerRestePerso(categorie, montant, person) {
      return Math.floor(montant - this.achats.reduce((total, achat) => {
        if (achat.categorie === categorie && achat.person === person) {
          return total + achat.montant;
        }
        return total;
      }, 0));
    },

    calculEpargne(ratio, person){
      if(person === "Chloe")
        return Math.floor(this.resteApresChloe * this[ratio].value)
      else
        return Math.floor(this.resteApresWill * this[ratio].value)
    },

    //#endregion

    //#region data

    async ajouterAchat() {
      if (!this.newCat || !this.newMount || !this.newDate || !this.newLabel || !this.newPerson){
        const response = await axios.get('https://api.w-c.website:3000/achats');
        console.log(response);
        return;
      }


      try{        
        await axios.post('https://api.w-c.website:3000/achats',  {
          categorie: this.newCat,
          montant: this.newMount,
          date: this.formaterDate(this.newDate),
          person: this.newPerson,
          label: this.newLabel,
        });
        this.fetchAchats();

        // Réinitialise les champs de saisie
        this.newCat = "Abonnement";
        this.newMount = "";
        this.newDate = new Date().toISOString().split('T')[0];
        this.newLabel = "";
        this.newPerson = "Commun";
      } catch (error) {
        throw new Error(`Erreur lors de l'ajout de l'achat : ${error.message}`);
      } 
      
    },

    async deleteAchat(id){
      let validation = confirm("Tu veux vraiment le supprimer ?");

      if(validation){
        try{        
          await axios.delete(`https://api.w-c.website:3000/achats/${id}`);
          this.fetchAchats();
        } catch (error) {
          throw new Error(`Erreur lors de l'ajout de l'achat : ${error.message}`);
        } 
      }
      else{
        return;
      }
    },

    async updateConfig(id, name){
      try{
        axios.patch(`https://api.w-c.website:3000/config/${id}`, { value: this[name].value })
      }
      catch(error){
        return;
      }
    },

    async fetchAchats(){
      try{
        const response = await axios.get('https://api.w-c.website:3000/achats');
          
        
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();
          

          const achatsDuMois = response.data.filter(a => {
            return a.date.substr(3, 2) - 1 == currentMonth && a.date.substr(6, 4) == currentYear;
          })

        this.achats = achatsDuMois;
      } catch (error) {
        return;
      }
    },

    async fetchConfig(){
      try{
        const response = await axios.get('https://api.w-c.website:3000/config');
        for(const item of response.data){
          this[item.name] = {id: item.id, name: item.name, value: item.value};
        }
        this.calculMontant();
      }
      catch(error){
        return;
      }
    },

    async tryToIn(){
      if(this.tryTo === this.pass.value)
        this.logged = true;

      return;
    },

    //#endregion

    //#region tools

    validateNumber(event) {
      // Empêche la saisie de tout ce qui n'est pas un chiffre
      if (!/^\d*\.?\d*$/.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9.]/g, '');
      }
    },

    formaterDate(dateIso) {
      const date = new Date(dateIso);
      const jour = date.getDate().toString().padStart(2, '0');
      const mois = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 car les mois commencent à 0
      const annee = date.getFullYear();
      return `${jour}/${mois}/${annee}`;
    },

    dynamicCSS(person){
      if(person === "William")
        return 'color-william';
      else if (person === "Commun")
        return 'color-commun';
      else
        return 'color-chloe';
    },

    configMode(){
      if(this.config)
        this.config = false;
      else
        this.config = true;
    },

    logout(){
      this.logged = false;
    },

    //#endregion
  }
}
</script>

<style scoped>
.column-adjustement {
  height: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.header {
  text-align: center;
  margin: 20px 0;
}

.u-full-width.date-picker {
  border: 1px solid #ddd; /* Bordure subtile */
  padding: 8px 10px; /* Un peu de padding pour la lisibilité */
  border-radius: 4px; /* Coins arrondis pour un look doux */
  font-size: 1em; /* Taille de police standard */
  background-color: #fff; /* Arrière-plan blanc */
  color: #333; /* Couleur de texte standard */
  outline: none; /* Retirer l'outline au focus */
}

.u-full-width.date-picker:hover {
  border-color: #ccc; /* Changement de couleur au survol */
}

.u-full-width.date-picker:focus {
  border-color: #9ecaed; /* Couleur de bordure au focus */
  box-shadow: 0 0 5px #9ecaed; /* Ajout d'un shadow pour le focus */
}

.color-william{
  background-color: #8D6B94;
  color: white;
}

.color-chloe{
  background-color: #95D9C3;
}

.color-commun{
  background-color: #B5BEC6;
}

#btn-top-left {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 5px;
}

#btn-top-leftt{
  position:absolute;
  top: 10px;
  left: 170px;
  border-radius: 5px;
}

.input-container {
  display: flex;
  justify-content: center; /* Centre horizontalement */
  min-width:100%;
}

/* Assurez-vous que l'input est affiché en ligne si nécessaire */
.u-full-width.two.columns {
  display: inline-block; /* Ou "block" selon le besoin */
}

</style>
